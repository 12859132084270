import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const TermsModal = ({ setIsTermsModal }) => {
    const [check, setCheck] = useState(false);
    const navigate = useNavigate()

    const navigateToChat = () => {
        navigate("/chat")
    }

    return (
        <ModalBackground>
            <Modal className='termsBox'>
                <ModalCloseWrapper>
                    <ModalClose onClick={() => setIsTermsModal(false)}><IoClose /></ModalClose>
                </ModalCloseWrapper>
                <InputWrapper>
                    <span>
                        <input className='modalInput' type="checkbox" checked={check} onChange={() => setCheck(!check)} />
                    </span>
                    <CheckBoxLabel>
                        <strong>By checking the box, you confirm you are 18 or older to use our platform.</strong>
                    </CheckBoxLabel>
                </InputWrapper>
                <SubmitButtonWrapper>
                    <Button className='confirmBtn' onClick={navigateToChat} disabled={!check}>Confirm & continue</Button>
                </SubmitButtonWrapper>
            </Modal>
        </ModalBackground>
    )
}

export default TermsModal

const ModalBackground = styled.div({
    width: "100%",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: 'fixed',
    top: "0",
    left: "0",
    overflow: "hidden"
})

const Modal = styled.div({
    padding: "35px 20px",
    height: "fit-content",
    background: "white",
    borderRadius: "30px"
})

const ModalCloseWrapper = styled.div({
    display: "flex",
    flexDirection: "row-reverse",
    position: "relative"
})

const ModalClose = styled.button({
    color: 'gray',
    fontSize: "26px",
    background: "transparent",
    border: "none",
    position: "absolute",
    top: "-25px",
    right: "-10px"
})

const InputWrapper = styled.div({
    display: "flex",
    gap: "10px"
})

const CheckBoxLabel = styled.span({
    fontSize: "15px"
})

const SubmitButtonWrapper = styled.div({
    marginTop: "20px",
    display: "flex",
    flexDirection: "row-reverse"
})

const Button = styled.button({
    fontSize: "20px"
})
