import React, { useEffect } from 'react'
import { useChat } from '../contextApi/ChatContext'
import styled from 'styled-components'

const Header = () => {
    const { onlineUsers, receiver, setIsTyping, setMessage, setReceiver } = useChat()
    
    useEffect(() => {
        if (receiver !== undefined && !onlineUsers.find((user) => user.userId === receiver)) {
            setIsTyping(false)
            setMessage("")
            setReceiver("")
        }
    }, [onlineUsers, receiver, setIsTyping, setMessage, setReceiver]);

    return (
        <HeaderContainer>
            <LogoWrapper>
                <HeaderText>talk before fuck</HeaderText>
            </LogoWrapper>
            <HeaderRight>
                <LiveUsersWrapper>
                    <LiveUsersNumber>{onlineUsers.length} +</LiveUsersNumber>
                    <LiveUsersText>Live users</LiveUsersText>
                </LiveUsersWrapper>
            </HeaderRight>
        </HeaderContainer>
    )
}

export default Header

const HeaderContainer = styled.div`
    padding: 10px 20px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
        padding: 15px 10px;
    }
`

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
`

const HeaderText = styled.p`
    font-size: 1.5rem;
    font-weight: 700;
    transform: rotate(-4deg);
    color: #333;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`

const HeaderRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 768px) {
        align-items: center;
        width: 100%;
    }
`

const LiveUsersWrapper = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`

const LiveUsersNumber = styled.p`
    font-size: 1.5rem;
    color: #9DB2D7;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`

const LiveUsersText = styled.p`
    color: #b6d1f0;
    font-size: 0.9rem;
`
