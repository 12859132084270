import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Heart, 
  Music, 
  User, 
  BookOpen, 
  GraduationCap, 
  MessageCircle, 
  Headphones,
  Sparkles,
  Star
} from 'lucide-react';

const BTSFarabi = ({ setIsTermsModal }) => {
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [currentQuote, setCurrentQuote] = useState(0);

  const lyricalQuotes = [
    {
      text: "Sometimes, the silence guides our minds to places far away—this text dating app is where words bridge the distance, bringing hearts closer one message at a time.",
      author: "Inspired by Silent Conversations"
    },
    {
      text: "In the symphony of connections, we're composing a melody of unexpected love—where every message is a note, and every match is a harmony.",
      author: "Rhythms of the Heart"
    },
    {
      text: "Between the lines of text, between the beats of a shared playlist, love finds its rhythm—unexpected, unscripted, unforgettable.",
      author: "Digital Serenade"
    }
  ];

  useEffect(() => {
    const quoteRotation = setInterval(() => {
      setCurrentQuote((prev) => (prev + 1) % lyricalQuotes.length);
    }, 7000);

    return () => clearInterval(quoteRotation);
  }, []);

  const interestCategories = [
    { icon: <Music size={20} />, name: "Music Vibes" },
    { icon: <BookOpen size={20} />, name: "Study Partners" },
    { icon: <GraduationCap size={20} />, name: "Campus Life" }
  ];

  return (
    <PageWrapper>
      <AppContainer
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, ease: 'easeOut' }}
      >
        <HeaderSection>
          <LogoContainer>
            <Heart strokeWidth={1.5} />
            <Logo>BTS FARABI</Logo>
          </LogoContainer>
          <Tagline>Where Campus Stories Begin ❤️</Tagline>
        </HeaderSection>

        <ContentWrapper>
          <InterestSection>
            <SectionHeader>
              <User size={18} strokeWidth={1.5} />
              <span>Your Campus Vibe</span>
            </SectionHeader>
            <InterestGrid>
              {interestCategories.map((category, index) => (
                <InterestChip 
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  selected={selectedInterests.includes(category.name)}
                  onClick={() => {
                    setSelectedInterests(prev => 
                      prev.includes(category.name) 
                      ? prev.filter(item => item !== category.name)
                      : [...prev, category.name]
                    )
                  }}
                >
                  {category.icon}
                  <span>{category.name}</span>
                </InterestChip>
              ))}
            </InterestGrid>
          </InterestSection>

          <QuoteContainer>
            <AnimatePresence mode="wait">
              <RotatingQuote
                key={currentQuote}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                <Headphones size={20} strokeWidth={1.5} />
                <QuoteText>"{lyricalQuotes[currentQuote].text}"</QuoteText>
                <QuoteAuthor>— {lyricalQuotes[currentQuote].author}</QuoteAuthor>
              </RotatingQuote>
            </AnimatePresence>
          </QuoteContainer>

          <DescriptionContainer>
            <AppDescription>
              BTS Farabi isn't just a dating app—it's where academic passion meets romantic potential. Connect with students who share your wavelength, beyond just swipes.
            </AppDescription>
          </DescriptionContainer>

          <FeatureHighlights>
            <FeatureItem>
              <MessageCircle size={18} />
              <span>Meaningful Connections</span>
            </FeatureItem>
            <FeatureItem>
              <Star size={18} />
              <span>Lyrical Matchmaking</span>
            </FeatureItem>
          </FeatureHighlights>

          <ActionButton 
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setIsTermsModal(true)}
          >
            <Sparkles size={18} strokeWidth={1.5} />
            Start Your Love Story
            <Sparkles size={18} strokeWidth={1.5} />
          </ActionButton>

          <DisclaimerText>
            <span>🔒</span> Secure, Respectful, Authentic Connections
          </DisclaimerText>
        </ContentWrapper>
      </AppContainer>
    </PageWrapper>
  );
};

// Design Tokens
const colors = {
  background: '#0A0A0A',
  text: '#FFFFFF',
  primary: '#FF3366',
  secondary: '#6A11CB',
  accent: '#2575FC',
  gray: {
    900: '#121212',
    800: '#1E1E1E',
    700: '#2A2A2A',
    600: '#3A3A3A',
    500: '#4A4A4A'
  }
};

const typography = {
  fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem'
  }
};

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, ${colors.background}, ${colors.gray[900]});
  padding: 1rem;
  font-family: ${typography.fontFamily};
`;

const AppContainer = styled(motion.div)`
  width: 100%;
  max-width: 450px;
  min-height: 700px;
  height: auto;
  background-color: ${colors.gray[800]};
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 
    0 40px 60px -20px rgba(255, 51, 102, 0.2),
    0 20px 30px -10px rgba(37, 117, 252, 0.1);
  border: 1px solid ${colors.gray[700]};
  
  @media (max-width: 480px) {
    max-width: 100%;
    min-height: 100vh;
    height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }
`;

const HeaderSection = styled.div`
  background: linear-gradient(to right, ${colors.primary}, ${colors.secondary});
  color: ${colors.text};
  text-align: center;
  padding: 2rem 1.5rem;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.2);
    z-index: 1;
  }

  @media (max-width: 480px) {
    padding: 1.5rem 1rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-bottom: 1rem;
  color: ${colors.text};
  position: relative;
  z-index: 2;

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const Logo = styled.h1`
  font-size: 2.5rem;
  font-weight: 900;
  letter-spacing: -2px;
  background: linear-gradient(to right, ${colors.text}, #CCCCCC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Tagline = styled.p`
  font-size: ${typography.fontSize.xs};
  opacity: 0.8;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
`;

const ContentWrapper = styled.div`
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex-grow: 1;
  justify-content: space-between;

  @media (max-width: 480px) {
    padding: 1.5rem 1rem;
    gap: 1rem;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.primary};
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: ${typography.fontSize.sm};
`;

const InterestSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const InterestGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media (max-width: 480px) {
    gap: 0.5rem;
  }
`;

const InterestChip = styled(motion.button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 12px;
  background: ${props => props.selected ? colors.primary : colors.gray[700]};
  color: ${props => props.selected ? colors.text : colors.gray[500]};
  border: none;
  transition: all 0.3s ease;
  font-size: ${typography.fontSize.xs};

  @media (max-width: 480px) {
    padding: 0.75rem;
  }
`;

const QuoteContainer = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 0.5rem;
  min-height: 120px;

  @media (max-width: 480px) {
    min-height: 100px;
  }
`;

const RotatingQuote = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  color: ${colors.accent};
`;

const QuoteText = styled.p`
  font-style: italic;
  font-size: ${typography.fontSize.sm};
  max-width: 90%;
  line-height: 1.6;

  @media (max-width: 480px) {
    font-size: ${typography.fontSize.xs};
  }
`;

const QuoteAuthor = styled.span`
  font-size: ${typography.fontSize.xs};
  opacity: 0.7;
  margin-top: 0.5rem;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
`;

const AppDescription = styled.p`
  color: ${colors.text};
  font-size: ${typography.fontSize.xs};
  opacity: 0.7;
  line-height: 1.6;
`;

const FeatureHighlights = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 480px) {
    gap: 0.5rem;
  }
`;

const FeatureItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: ${colors.gray[700]};
  color: ${colors.text};
  padding: 1rem;
  border-radius: 12px;
  font-size: ${typography.fontSize.xs};
  opacity: 0.8;

  @media (max-width: 480px) {
    padding: 0.75rem;
  }
`;

const ActionButton = styled(motion.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 1.25rem;
  background: linear-gradient(to right, ${colors.primary}, ${colors.secondary});
  color: ${colors.text};
  border: none;
  border-radius: 16px;
  font-weight: 600;
  font-size: ${typography.fontSize.sm};
  transition: all 0.3s ease;

  @media (max-width: 480px) {
    padding: 1rem;
    font-size: ${typography.fontSize.xs};
  }
`;

const DisclaimerText = styled.div`
  background: ${colors.gray[700]};
  border: 1px solid ${colors.gray[600]};
  padding: 1rem;
  text-align: center;
  border-radius: 12px;
  color: ${colors.text};
  font-size: ${typography.fontSize.xs};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 480px) {
    padding: 0.75rem;
  }
`;

export default BTSFarabi;
