import React, { useEffect, useRef } from 'react'
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import { Sparkles, RefreshCw, Download, MessageCircle, UserX } from 'lucide-react';

// Import your context and socket
import { useChat } from '../../contextApi/ChatContext'
import { socket } from '../../Socket'

const Messages = () => {
    const { 
        userId, 
        isSearching, 
        setIsSearching, 
        receiver, 
        messages, 
        setMessages, 
        isTyping 
    } = useChat()

    const newChat = () => {
        setIsSearching(true)
        setMessages([])
        socket.emit("pairing-user", userId, (error) => {
            if (error) {
                console.error(error);
                return;
            }
        })
    }

    const takeScreenshot = () => {
        const element = document.getElementById('chat-log');
        html2canvas(element, { 
            useCORS: true, 
            scale: 2 
        }).then((canvas) => {
            const screenshot = canvas.toDataURL('image/png');
            const downloadLink = document.createElement('a');
            downloadLink.href = screenshot;
            downloadLink.download = `chat_log_${new Date().toISOString().slice(0,10)}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }).catch(err => {
            console.error("Screenshot failed:", err);
        });
    };

    const messagesRef = useRef()

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <ChatContainer id='chat-log' ref={messagesRef}>
            {!isSearching && !receiver && (
                <EmptyState>
                    <MessageCircle size={64} strokeWidth={1.5} className="text-blue-500" />
                    <WelcomeTitle>Connect Anonymously</WelcomeTitle>
                    <WelcomeSubtitle>Start a conversation with a random stranger</WelcomeSubtitle>
                    <StartButton onClick={newChat}>
                        <Sparkles size={16} className="mr-2" /> Begin Conversation
                    </StartButton>
                </EmptyState>
            )}

            {receiver && (
                <ConnectedBanner>
                    <p>🌐 Chatting with an anonymous stranger</p>
                </ConnectedBanner>
            )}

            <MessagesWrapper>
                {messages.map((message, index) => (
                    <MessageItem 
                        key={index} 
                        $isStranger={message?.stranger}
                    >
                        <MessageHeader $isStranger={message?.stranger}>
                            {message?.stranger ? "Anonymous Stranger" : "You"}
                        </MessageHeader>
                        <MessageContent>
                            {message?.stranger ? message.stranger : message.you}
                        </MessageContent>
                    </MessageItem>
                ))}

                {isTyping && (
                    <TypingIndicator>
                        <p>Stranger is typing...</p>
                    </TypingIndicator>
                )}

                {isSearching && (
                    <SearchingState>
                        <RefreshCw 
                            size={24} 
                            className="animate-spin text-blue-500 mr-2" 
                        />
                        Looking for a conversation partner...
                    </SearchingState>
                )}

                {!isSearching && !receiver && (
                    <DisconnectedState>
                        <UserX size={48} className="text-red-500 mb-4" />
                        <p>Conversation ended</p>
                        <ActionButtons>
                            <NewChatButton onClick={newChat}>
                                <RefreshCw size={16} className="mr-2" /> 
                                New Conversation
                            </NewChatButton>
                            <SaveLogButton onClick={takeScreenshot}>
                                <Download size={16} className="mr-2" />
                                Save Chat Log
                            </SaveLogButton>
                        </ActionButtons>
                    </DisconnectedState>
                )}
            </MessagesWrapper>
        </ChatContainer>
    )
}

export default Messages


// Styled Components with Modern Design
const ChatContainer = styled.div`
    height: 71vh;
    overflow-y: auto;
    background-color: #f4f6f9;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const EmptyState = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
`;

const WelcomeTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: bold;
    color: #2563eb;
    margin-top: 15px;
`;

const WelcomeSubtitle = styled.p`
    color: #718096;
    margin-bottom: 20px;
`;

const StartButton = styled.button`
    display: flex;
    align-items: center;
    padding: 12px 20px;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #1d4ed8;
    }
`;

const MessagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const MessageItem = styled.div`
    max-width: 80%;
    align-self: ${props => props.$isStranger ? 'flex-start' : 'flex-end'};
    background-color: ${props => props.$isStranger ? '#e6f2ff' : '#f0f9ff'};
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 10px;
`;

const MessageHeader = styled.div`
    font-weight: bold;
    color: ${props => props.$isStranger ? '#d63031' : '#0984e3'};
    margin-bottom: 8px;
`;

const MessageContent = styled.div`
    font-size: 1rem;
    color: #2d3748;
`;

const TypingIndicator = styled.div`
    color: #718096;
    font-style: italic;
    padding: 10px;
`;

const SearchingState = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2563eb;
    padding: 20px;
    background-color: #e6f2ff;
    border-radius: 12px;
`;

const ConnectedBanner = styled.div`
    background-color: #48bb78;
    color: white;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
`;

const DisconnectedState = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-color: #f7fafc;
    border-radius: 16px;
    text-align: center;
`;

const ActionButtons = styled.div`
    display: flex;
    gap: 15px;
    margin-top: 20px;
`;

const NewChatButton = styled.button`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
`;

const SaveLogButton = styled.button`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #48bb78;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
`;
